import gql from "graphql-tag";

export const updateClientByTokenMutationFragment = gql`
	fragment updateClientByTokenMutationFragment on UpdateOneClientPayload {
		record {
			_id
		}
	}
`;

export const updateClientNotificationByTokenMutationFragment = gql`
	fragment updateClientNotificationByTokenMutationFragment on GenericPayload {
		success
	}
`;

export const createLeadMutation = gql`
	mutation CreateLead($input: CreateLeadInput) {
		createLead(input: $input) {
			success
			record {
				_id
				key
				active
			}
		}
	}
`;

export const addLeadPicksMutation = gql`
	mutation AddLeadPicks(
		$leadKey: ID!
		$providers: [ID]!
		$autoSelected: Boolean
	) {
		addLeadPicks(
			leadKey: $leadKey
			providers: $providers
			autoSelected: $autoSelected
		) {
			success
		}
	}
`;

import Step from "../../../@extensions/Step";
import { observable, runInAction, action, computed, toJS } from "mobx";

import { searchService, leadService } from "../../../@data/service";
import required from "@libs/required";

const { searchByLead, searchByAutoBill } = searchService;

const { addLeadPicks, findPartnerOpportunity } = leadService;
const { SITE_NAME } = process.env;
class MoreProsStore extends Step {
	@observable showAreYouSure = false;

	constructor(rootStore) {
		super(rootStore);
	}

	get lead() {
		return super.lead;
	}

	get Lead() {
		return this.leadFunnelStore.Lead;
	}

	get matches() {
		const { liaisonStore } = this.rootStore.stores;
		return liaisonStore.matches;
	}

	get partner() {
		if (this.Lead.sold.show_widget) {
			return this.Lead.sold.partner;
		}
		return "liaison";
	}

	get angiLeadKey() {
		if (this.partner === "ha") {
			return this.Lead.sold.partner_lead_key;
		}
		return null;
	}

	get networxUrl() {
		if (this.partner === "networx") {
			return decodeURI(this.Lead.sold.redirect_url);
		}
		return null;
	}

	@action
	setShowAreYouSure(bool = required`bool`) {
		this.showAreYouSure = bool;
	}

	async removePicks() {
		try {
			const { liaisonStore } = this.rootStore.stores;
			liaisonStore.deselectAll();
		} catch (err) {
			throw err;
		}
	}

	async next() {
		try {
			const { angiStore, liaisonStore, networxStore } = this.rootStore.stores;

			switch (this.partner) {
				case "ha":
					await angiStore.complete();
					// await this.topOff();
					break;
				case "liaison":
					await liaisonStore.complete();
					await this.topOff();
					break;
				case "networx":
					await networxStore.complete();
					await this.topOff();
					break;
			}

			super.next();
		} catch (err) {
			throw err;
		}
	}

	async topOff() {
		try {
			if (["homeguide"].includes(SITE_NAME)) {
				const { liaisonStore } = this.rootStore.stores;
				const { response } = await searchByAutoBill({
					leadKey: this.lead.key,
				});

				liaisonStore.matches = liaisonStore.matches.concat(
					response?.providers
						.filter(
							(provider) =>
								!liaisonStore.matches.find(
									(match) => match.provider._id === provider._id
								)
						)
						.map((provider) => ({ provider })) || []
				);

				const picks =
					response?.providers?.map((provider) => provider._id) || [];

				if (picks.length > 0) {
					await addLeadPicks({
						leadKey: this.lead.key,
						providers: picks,
						autoSelected: true,
					});
					this.Lead.picks = this.Lead.picks.concat(picks);
				}
			}
		} catch (err) {
			throw err;
		}
	}
}

export default MoreProsStore;
